import axios from 'axios'
import { Toast,Dialog } from 'vant';
// import Constant from '@/utils/constant'

// create an axios instance
const service = axios.create({
  timeout: 20000 // request timeout
})

let loading = undefined;

// request interceptor
service.interceptors.request.use(
    config => {
        //发起请求时，带上最新的token

        loading = Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
        overlay: true
    });
    return config;
  },
  err => Promise.reject(err)
)

// response interceptor
service.interceptors.response.use(
  response => {
      if(loading){
          loading.clear();
      }
      return response;
  }, error => {
        if(loading){
            loading.clear();
        }
        for (let i = 0; i < 5; i++) {
            if(i===5){
                Dialog.alert({
                    title: '警告',
                    message: '请求超时'
                })
            }else{
                window.location.reload(true)
            }
        }
        // Dialog.alert({
        //     title: '警告',
        //     message: '请求超时'
        // }).then(() => { //点击确认按钮后的调用
        //         console.log("点击了确认按钮噢")
        //     window.location.reload(true)
        //     });
    return Promise.reject(error)
  })

export default service
