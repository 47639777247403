import Vue from 'vue'
import VueRouter from 'vue-router'
import routerConfig from './router.config'

Vue.use(VueRouter)
//获取原型对象上的push函数

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
    // mode:'history',
    routes:routerConfig,
    scrollBehavior(to,from,savedPosition){
        if(savedPosition){
            return savedPosition
        }else {
            return {
                x:0,
                y:0
            }
        }
    }

})


//拦截器
// router.beforeEach((to, from, next) => {
//     if (to.meta.requireAuth) {//需要登录拦截
//         if (sessionStorage.getItem(Constant.HEADER_AUTHORIZATION)) {//判断当前用户本地有无存储登录token
//             next()
//             return;
//         } else {
//             if (to.path === '/login') {
//                 next()
//                 return;
//             } else {
//                 next({path: '/login'})
//                 return;
//             }
//         }
//     } else {
//         next()
//         return;
//     }

// })


export default router

