<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
import {getMeta} from "@/api/api";

export default {
  name:'app',
  provide(){
    return{
      reload:this.reload
    }
  },
  data(){
    return{
      isRouterAlive:true,
      setting: {
        title: "",
        keywords: "",
        description: "",
      },
    }

  },
  metaInfo() {
    return {
      title: this.setting.title,
      meta: [
        { name: "keywords", content: this.setting.keywords },
        { name: "description", content: this.setting.description },
      ],
    };
  },
  mounted() {
    this.togetMeta()
  },
  methods:{
    reload(){
      this.isRouterAlive=false
      this.$nextTick(function (){
        this.isRouterAlive=true
      })
    },

    togetMeta(){
      // 模拟接口获取数据
      let _this=this
      getMeta()
      .then( r => {
        let res=r.data.data
        _this.setting.title = res.title;
        document.querySelector('meta[name="keywords"]').setAttribute('content', res.keywords);
        document.querySelector('meta[name="description"]').setAttribute('content', res.desc);
      })


    }
  }
}
</script>
