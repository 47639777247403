import request  from '@/utils/request'

let basePath1 = process.env.VUE_APP_BASE_API1
// let basePath2 = process.env.VUE_APP_BASE_API2
// let basePath3 = process.env.VUE_APP_BASE_API3

//发短信验证码
// export function sendSmsApi(query) {
//     return request({
//         url: 'business/send',
//         method: 'get',
//         params:query
//     })
// }


//获取数据
export function GetYear(){
    return request({
        url: basePath1+'index/api/getYears?type=1',
        method: 'get',
    })
}
//获取版权，meta数据
export function getMeta(){
    return request({
        url: basePath1+'index/api/getMeta',
        method: 'get',
    })
}
//获取数据
export function GetIndexDoc(data){
    return request({
        url: basePath1+'index/api/getDoc?from=m&page_index='+data,
        method: 'get',
    })
}
//获取轮播
export function GetLunbo(){
    return request({
        url: basePath1+'index/api/getShuffling?place=1',
        method: 'get',
    })
}

//获取菜单
export function getMenu() {
    return request({
        url: basePath1+'index/api/nav?from=m',
        method: 'get',
    })
}
//获取关于微光历程
export function GetAboutLicheng(data) {
    return request({
        url: basePath1+'index/api/licheng?from=m&year='+data,
        method: 'get',
    })
}
//获取关于微光荣誉
export function GetAboutRongyu(data) {
    return request({
        url: basePath1+'index/api/rongyu?from=m&year='+data,
        method: 'get',
    })
}
//获取关于微光荣誉
export function GetAboutVideo() {
    return request({
        url: basePath1+'index/api/shipin?from=m&page=1',
        method: 'get',
    })
}
//获取关于微光荣誉
export function GetXingPics() {
    return request({
        url: basePath1+'index/api/xing?from=m',
        method: 'get',
    })
}
//获取新闻动态
export function GetNewsData(data) {
    return request({
        url: basePath1+'index/api/news?from=m',
        method: 'get',
        params:data
    })
}
//获取新闻动态
export function GetDiseaseKepu() {
    return request({
        url: basePath1+'index/api/illKnowledge?id=24&from=m',
        method: 'get',
    })
}
//获取新闻动态
export function GetShizhanData(data) {
    return request({
        url: basePath1+'index/api/illVideo?from=m',
        method: 'get',
        params:data

    })
}
//获取新闻动态
export function GetXunzhengData() {
    return request({
        url: basePath1+'index/api/illDoc?page=1&from=m',
        method: 'get'

    })
}
//获取询证详情
export function GetXunzhengDetail(data) {
    return request({
        url: basePath1+'index/api/illDocDetail?from=m&id='+data,
        method: 'get'

    })
}
//获取新闻动态详情
export function GetNewsDetail(data) {
    return request({
        url: basePath1+'index/api/newsDetail?from=m&id='+data,
        method: 'get'

    })
}
//获取产品列表1
export function GetProductData1() {
    return request({
        url: basePath1+'index/api/productCate?from=m&type=1',
        method: 'get'
    })
}
//获取产品列表2
export function GetProductData2() {
    return request({
        url: basePath1+'index/api/productCate?from=m&type=2',
        method: 'get'
    })
}
//获取产品信息
export function GetProductDetailData(data) {
    return request({
            url: basePath1+'/index/api/productDetail?from=m&id='+data,
        method: 'get'
    })
}
//获取产品信息
export function GetAddressData() {
    return request({
            url: basePath1+'/index/api/getAddress',
        method: 'get'
    })
}
