import Vue from 'vue'
import {
    Button,
    Message,
    collapseItem,
    collapse,
    table,
    tableColumn





} from 'element-ui'

Vue.use(Button)
Vue.use(collapseItem)
Vue.use(collapse)
Vue.use(table)
Vue.use(tableColumn)

Vue.prototype.$message=Message
